import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Row, Col } from 'antd';
import { ContentContainer, SectionContainer, HTwo, HThree, Paragraph } from "components";
import { 
  BarChartOutlined,
  MobileOutlined,
  UserSwitchOutlined,
  QrcodeOutlined,
  FileProtectOutlined,
  FileDoneOutlined
} from "@ant-design/icons";
import useMediaQuery from "hooks/useMediaQuery";
import solutionIcon from "assets/images/solution-icon.png";
import solutionBg from "assets/images/solution-bg.png";

const icons = [
  <UserSwitchOutlined />,
  <QrcodeOutlined />,
  <MobileOutlined />,
  <FileProtectOutlined />,
  <BarChartOutlined />,
  <FileDoneOutlined />,
]

const OurSolution = () => {

  const isMobile = useMediaQuery('max-width: 576px')
  const { t } = useTranslation()

  return (
    <StyledSectionContainer>
      <StyledContentContainer>
        <HTwo><Trans i18nKey="solutions.header" components={[<span />]} /></HTwo>
        {(isMobile) ? (
          <ItemContainer gutter={0}>
          </ItemContainer>
        ) : (
          <ItemContainer gutter={40}>
            {new Array(6).fill(true).map((_, i) => (
              <Item sm={24} md={12} lg={8} key={i}>
                <div>
                  <div>
                    {icons[i]}
                    <HThree>{t(`solutions.${i}.header`)}</HThree>
                  </div>
                  <div>
                    <Paragraph>{t(`solutions.${i}.text`)}</Paragraph>
                  </div>
                </div>
              </Item>
            ))}
          </ItemContainer>
        )}
      </StyledContentContainer>
    </StyledSectionContainer>
  )
}

const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0;
  background-image: url(${solutionBg});
  background-size: cover;
  & ${HTwo} {
    padding-bottom: 20px;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0; left: 0; width: 100%; height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 576px) {
    padding: 30px;
    text-align: left;
  }
`;
const StyledContentContainer = styled(ContentContainer)`
  position: relative;
  z-index: 2;
`;
const ItemContainer = styled(Row)`
  & .anticon {
    color: var(--primary-green);
    width: 60px;
    font-size: 30px;
  }
`;
const StyledImg = styled.img`
  height: 50px;
  width: 50px;
  object-fit: contain;
`;
const Item = styled(Col)`
  & > div {
    text-align: left;
    padding: 20px;
    padding-bottom: 5px;
    margin-bottom: 40px;
    border: 1px solid var(--primary-green);
    background: var(--primary-color-dark);
    border-radius: 6px;
    min-height: 240px;
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      & ${StyledImg} {
        margin-right: 20px;
      }
      & ${HThree} {
        color: var(--primary-green);
        margin: 0;
        padding-right: 40px;
      }
      &:first-child {
        align-items: start;
      }
      &:nth-child(2) {
        flex-grow: 1;
      }
    }
    & ${Paragraph} {
      color: white;
    }
  }
`;
export default OurSolution;
