import styled from "styled-components"; 
import { Row } from 'antd';
import { useTranslation, Trans } from "react-i18next";
import { SlimContentContainer, ContentContainer, SectionContainer, HTwo, Paragraph } from "components";
import dashboard from "assets/images/dashboard.png";

const Dashboard = () => {

  const { t } = useTranslation()

  return (
    <StyledSectionContainer id="features">
      <ContentContainer>
        <SlimContentContainer>
          <HTwo><Trans i18nKey="dashboard.header" components={[<span />]} /></HTwo>
          <Paragraph>{t("dashboard.description")}</Paragraph>
        </SlimContentContainer>
        <StyledRow>
          <StyledImg src={dashboard} alt="F5 Dashboard" />
        </StyledRow>
      </ContentContainer>
    </StyledSectionContainer>
  )
}

const StyledImg = styled.img`
  box-shadow: 60px 0px 150px var(--very-faded-gradient-color);
  @media(max-width: 768px) {
    position: relative;
    left: 50px;
  }
  width: 100%;
  max-width: 900px;
`;
const StyledRow = styled(Row)`
  margin-top: 80px;
  @media(max-width: 768px) {
    margin-top: 40px;
  }
  `;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 60px 0;
  padding-bottom: 0px;
  background: var(--primary-color-dark);
  background-image: radial-gradient(circle at 50% 150%, var(--faded-gradient-color), transparent 70%);
  @media(max-width: 675px) {
    padding: 20px 0;
  }
`;
export default Dashboard;
