import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Row, Col } from 'antd';
import { SectionContainer, HTwo, Paragraph } from "components";
import visualizeSuccess from "assets/images/visualize-success.png";

const VisualizeSuccess = () => {

  const { t } = useTranslation()

  return (
    <StyledSectionContainer>
      <StyledRow>
        <StyledCol sm={24} md={12}>
          <HTwo><Trans i18nKey="visualiseSuccess.header" components={[<span />]} /></HTwo>
          <Paragraph>{t("visualiseSuccess.subHeader")}</Paragraph>
        </StyledCol>
        <ImgCol sm={24} md={12} >
          <StyledImg src={visualizeSuccess} alt="Visualize success" />
        </ImgCol>
      </StyledRow>
    </StyledSectionContainer>
  )
}

const StyledCol = styled(Col)`
  padding: 0 40px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media(max-width: 576px) {
    padding: 20px;
    text-align: right;
  }
  `;
const StyledRow = styled(Row)`
  width: 90%;
  `;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0;
  padding-bottom: 0px;
  text-align: left;
  flex-direction: column;
  @media(max-width: 576px) {
    padding: 20px 0;
  }
`;
const StyledImg = styled.img`
  margin: auto;
  align-self: center;
  position: relative;
`;
const ImgCol = styled(StyledCol)`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -16%; left: -16%; height: 133%; width: 133%;
    background: radial-gradient(circle at 50% 50%, var(--faded-gradient-color), transparent 70%);
  }
`;
export default VisualizeSuccess;
