import styled from "styled-components";
import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { SectionContainer, ContentContainer } from "components";


export default () => {

  const { i18n } = useTranslation()
  useEffect(() => window.scrollTo(0, 0), [])

  return (
    <SectionContainer>
      <StyledContentContainer>
        {i18n.language == "nl" ? dutch : english}
      </StyledContentContainer>
    </SectionContainer>
  )
}

const StyledContentContainer = styled(ContentContainer)`
  color: var(--text-color);
  text-align: left;
  line-height: 1.9;
  & table {
    border: 1px solid var(--border-color);
  }
`;

const english = (
  <>
    <p><strong>PRIVACY POLICY</strong></p>
    <p>F5Scan BV, located at Phoenixstraat 54 2611 AM Delft, The Netherlands, is responsible for the processing of personal data as shown in this privacy statement.</p>
    <p>&zwj;</p>
    <p>Contact details:</p>
    <p>https://f5scan.com</p>
    <p></p>
    <p>Phoenixstraat 54 2611 AM Delft, The Netherlands</p>
    <p>+31 15 241 0024</p>
    <p>Mohamad Alamili is the Data Protection Officer of F5Scan BV. He can be reached via <a href="mailto:info@f5scan.com">info@f5scan.com</a> .</p>
    <p></p>
    <p><strong>Personal data that we process</strong></p>
    <p>Weaver Technologies BV processes your personal data because you use our services and/or because you provide this data to us yourself. Below, you will find an overview of the personal data that we process:</p>
    <ul>
        <li>First and last name</li>
        <li>Telephone number</li>
        <li>E-mail address</li>
        <li>IP address</li>
        <li>Other personal data that you actively provide, for example by creating a profile on this website, in correspondence and by telephone</li>
        <li>Information about your activities on our website</li>
        <li>Data about your surfing behaviour across different websites (for example because this company is part of an advertising network)</li>
        <li>List of customer contact details via an app</li>
        <li>Internet browser and device type</li>
    </ul>
    <p></p>
    <p>Our website and/or service does not intend to collect data about website visitors who are younger than 16 years old. Unless they have parental or guardian permission. However, we cannot check whether a visitor is older than 16. We therefore advise parents to be involved in the online activities of their children, to prevent data about children being collected without parental consent. If you are convinced that we have collected personal information about a minor without this permission, please contact us at support@weaverhq.com, and we will delete this information.</p>
    <p></p>
    <p><strong>For what purpose and on what basis we process personal data</strong></p>
    <p>Weaver Technologies BV processes your personal data for the following purposes:</p>
    <ul>
        <li>Handling your payment.</li>
        <li>Sending our newsletter and/or advertising brochure.</li>
        <li>To be able to call or e-mail you if this is necessary to be able to carry out our services</li>
        <li>Offer you the option to create an account.</li>
        <li>Weaver Technologies BV analyses your behaviour on the website to improve the website and to tailor the range of products and services to your preferences.</li>
        <li>Weaver Technologies BV also processes personal data if we are legally obliged to do so, such as data that we need for our tax return.</li>
    </ul>
    <p></p>
    <p><strong>How long we keep personal data</strong></p>
    <p>Weaver Technologies BV does not store your personal data for longer than is strictly necessary to realize the purposes for which your data is collected. We use the following retention periods for the following (categories) of personal data:</p>
    <p>Category               Retention period      </p>
    <p>Contractual data        7 years after cancellation of the contract</p>
    <p>Marketing data         2 years</p>
    <p>Behaviour analysis      2 years</p>
    <p></p>
    <p><strong>Sharing personal data with third parties</strong></p>
    <p>Weaver Technologies BV shares your personal data with various third parties if this is necessary for the execution of the agreement and to comply with any legal obligation. We conclude a processing agreement with companies that process your data on our behalf to ensure the same level of security and confidentiality of your data. Weaver Technologies BV remains responsible for these processing operations. In addition, Weaver Technologies bv provides your personal data to other third parties. We only do this with your explicit permission.</p>
    <p>Category         Name             Purpose                              What data</p>
    <p>Authentication    Auth0            User and Access Management         User data</p>
    <p>                                                                          - Name</p>
    <p>                                                                          - E-mail address</p>
    <p>Subscription.     Chargebee        Subscription management            - First and last name</p>
    <p>                                                                          - Telephone number</p>
    <p>                                                                          - E-mail address</p>
    <p>                                                                          - IP address</p>
    <p>                                                                          - Other personal data that you actively provide</p>
    <p>                                                                          - List of customer contact details</p>
    <p>Support          Freshdesk         Support desk                         - Name</p>
    <p>                                                                          - E-mail address</p>
    <p><strong>Cookies, or similar techniques, that we use</strong></p>
    <p>Weaver Technologies BV uses functional, analytical and tracking cookies. A cookie is a small text file that is stored in the browser of your computer, tablet or smartphone when you first visit this website. Weaver Technologies BV uses cookies with a purely technical functionality. These ensure that the website works properly and that, for example, your preferred settings are remembered. These cookies are also used to make the website work properly and to optimize it. In addition, we place cookies that keep track of your surfing behaviour so that we can offer customized content and advertisements. On your first visit to our website, we already informed you about these cookies, and we asked for your permission to place them. You can opt out of cookies by setting your internet browser so that it no longer stores cookies. In addition, you can also delete all information that was previously stored via the settings of your browser.</p>
    <p></p>
    <p>Cookies are also placed on this website by third parties. These are, for example, advertisers and/or social media companies. Below is an overview:</p>
    <p>Name                Explanation                                                  Retention period</p>
    <p>Google Analytics.     Analytical cookie that measures website visits.                 2 years</p>
    <p>Hotjar.                Analytical cookie that measures website visits.                 2 years</p>
    <p><strong>View, modify or delete data</strong></p>
    <p>You are entitled to view, correct or delete your personal data. In addition, you are entitled to withdraw your consent to the data processing or to object to the processing of your personal data by Weaver Technologies BV, and you have the right to data portability. This means that you can submit a request to us to send the personal data we have about you in a computer file to you or another organization mentioned by you. You can send a request for access, correction, deletion, data transfer of your personal data or request for withdrawal of your consent or objection to the processing of your personal data to support@weaverhq.com. To ensure that the request for access has been made by you, we ask you to send a copy of your proof of identity with the request. Make your passport photo, MRZ (machine-readable zone, the strip with numbers at the bottom of the passport), passport number and Citizen Service Number (BSN) black in this copy. This is to protect your privacy. We will respond to your request as soon as possible, but within four weeks. Weaver Technologies BV would also like to point out that you have the option of submitting a complaint to the national supervisory authority, the Dutch Data Protection Authority. You can do this via the following link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</p>
    <p></p>
    <p><strong>How we protect personal data</strong></p>
    <p>Weaver Technologies BV takes the protection of your data seriously and takes appropriate measures to prevent misuse, loss, unauthorized access, unwanted disclosure, and unauthorized modification. If you feel that your data is not properly secured or there are indications of misuse, please contact our customer service or via <a href="mailto:info@f5scan.com">info@f5scan.com</a> !</p>
  </>
)

const dutch = (
  <>
    <p><strong>PRIVACYBELEID</strong></p>
    <p>F5Scan BV, gevestigd aan Phoenixstraat 54 2611 AM Delft, Nederland, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>
    <p>&zwj;</p>
    <p>Contact details:</p>
    <p>https://f5scan.com</p>
    <p></p>
    <p>Phoenixstraat 54 2611 AM Delft, Nederland</p>
    <p>+31 15 241 0024</p>
    <p>Mohamad Alamili is de Functionaris Gegevensbescherming van F5Scan BV. Hij is te bereiken via <a href="mailto:info@f5scan.com">info@f5scan.com</a> .</p>
    <p></p>
    <p><strong>Persoonsgegevens die wij verwerken</strong></p>
    <p>Weaver Technologies BV verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze gegevens zelf aan ons verstrekt.Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:</p>
    <ul>
        <li>Voor-en achternaam</li>
        <li>Telefoon nummer</li>
        <li>E-mailadres</li>
        <li>IP adres</li>
        <li>Overige persoonsgegevens die u actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch</li>
        <li>Gegevens over uw activiteiten op onze website</li>
        <li>Gegevens over uw surfgedrag over verschillende websites heen (bijvoorbeeld omdat dit bedrijf onderdeel is van een advertentienetwerk)</li>
        <li>Lijst met klantcontactgegevens via een app</li>
        <li>Internetbrowser en apparaattype</li>
    </ul>
    <p></p>
    <p>Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar.Tenzij ze toestemming van hun ouders of voogd hebben.Wij kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming.Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via support@weaverhq.com, dan verwijderen wij deze informatie.</p>
    <p></p>
    <p><strong>Met welk doel en op welke grondslag verwerken wij persoonsgegevens</strong></p>
    <p>Weaver Technologies BV verwerkt uw persoonsgegevens voor de volgende doeleinden:</p>
    <ul>
        <li>Het afhandelen van uw betaling.</li>
        <li>Verzenden van onze nieuwsbrief en/of reclamefolder.</li>
        <li>U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
        <li>Bied u de mogelijkheid om een account aan te maken.</li>
        <li>Weaver Technologies BV analyseert uw gedrag op de website om de website te verbeteren en het aanbod van producten en diensten af te stemmen op uw voorkeuren.</li>
        <li>Weaver Technologies BV verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze belastingaangifte.</li>
    </ul>
    <p></p>
    <p><strong>Hoe lang wij persoonsgegevens bewaren</strong></p>
    <p>Weaver Technologies BV bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld.Wij hanteren de volgende bewaartermijnen voor de volgende (categorie&euml;n) van persoonsgegevens:</p>
    <p>Categorie Bewaartermijn      </p>
    <p>Contractuele gegevens 7 jaar na opzegging van het contract</p>
    <p>Marketinggegevens 2 jaar</p>
    <p>Gedragsanalyse 2 jaar</p>
    <p></p>
    <p><strong>Het delen van persoonsgegevens met derden</strong></p>
    <p>Weaver Technologies BV deelt uw persoonsgegevens met verschillende derden als dit noodzakelijk is voor het uitvoeren van de overeenkomst en om te voldoen aan een eventuele wettelijke verplichting.Met bedrijven die in onze opdracht uw gegevens verwerken, sluiten wij een verwerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens.Weaver Technologies BV blijft verantwoordelijk voor deze verwerkingen.Daarnaast verstrekt Weaver Technologies bv uw persoonsgegevens aan andere derden.Dit doen wij alleen met uw uitdrukkelijke toestemming.</p>
    <p>Categorie Naam Doel Welke gegevens</p>
    <p>Authenticatie Auth0 Gebruiker en Toegangsbeheer Gebruikersgegevens</p>
    <p>                                                                          - Naam</p>
    <p>                                                                          - E-mailadres</p>
    <p>Abonnement. Chargebee Abonnementbeheer - Voor- en achternaam</p>
    <p>                                                                          - Telefoon nummer</p>
    <p>                                                                          - E-mailadres</p>
    <p>                                                                          - IP adres</p>
    <p>                                                                          - Overige persoonsgegevens die u actief verstrekt</p>
    <p>                                                                          - Lijst met contactgegevens van klanten</p>
    <p>Ondersteuning Freshdesk Supportdesk - Naam</p>
    <p>                                                                          - E-mailadres</p>
    <p><strong>Cookies, of vergelijkbare technieken, die wij gebruiken</strong></p>
    <p>Weaver Technologies BV gebruikt functionele, analytische en tracking cookies.Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van uw computer, tablet of smartphone.Weaver Technologies BV gebruikt cookies met een puur technische functionaliteit.Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden.Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren.Daarnaast plaatsen wij cookies die uw surfgedrag bijhouden zodat wij op maat gemaakte inhoud en advertenties kunnen aanbieden.Bij jouw eerste bezoek aan onze website hebben wij je al ge&iuml;nformeerd over deze cookies en hebben we je toestemming gevraagd voor het plaatsen ervan.U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat.Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.</p>
    <p></p>
    <p>Op deze website worden ook cookies geplaatst door derde partijen.Dit zijn bijvoorbeeld adverteerders en/of social media bedrijven.Hieronder vindt u een overzicht:</p>
    <p>Naam Toelichting Bewaartermijn</p>
    <p>Google Analytics.Analytische cookie die websitebezoek meet.2 jaar</p>
    <p>Hotjar.Analytische cookie die websitebezoek meet.2 jaar</p>
    <p><strong>Gegevens bekijken, wijzigen of verwijderen</strong></p>
    <p>U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen.Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door Weaver Technologies BV en heeft u het recht op gegevensoverdraagbaarheid.Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen.U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar support@weaverhq.com.Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen.Maak in deze kopie uw pasfoto, MRZ (machine leesbare zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart.Dit is ter bescherming van uw privacy.Wij reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek.Weaver Technologies BV wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens.Dit kunt u doen via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</p>
    <p></p>
    <p><strong>Hoe wij persoonlijke gegevens beschermen</strong></p>
    <p>Weaver Technologies BV neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via <a href="mailto:info@f5scan.com">info@f5scan.com</a> !</p>
    <p>   </p>
  </>
)