import styled from "styled-components"; 
import { useTranslation, Trans } from "react-i18next";
import { Row, Col } from 'antd';
import { SlimContentContainer, Button, SectionContainer, HTwo, Paragraph } from "components";
import checkLight from "assets/images/light-check.png";
import checkDark from "assets/images/dark-check.png";
import formLinks from "utils/formLinks";


const Simple = () => {

  const { t, i18n } = useTranslation()

  return (
    <StyledSectionContainer id="pricing">
      <SlimContentContainer>
        <HTwo><Trans i18nKey="pricing.header" components={[<span />]} /></HTwo>
        <Paragraph>{t("pricing.subHeader")}</Paragraph>
        <StyledRow gutter={24}>
          <Col sm={24} md={12}>
            <Panel>
              <div>
                <div>
                  <Strong>{t("pricing.free.header")}</Strong>
                  <StyledParagraph>{t("pricing.free.subHeader")}</StyledParagraph>
                </div>
                <div>
                  <HTwo>{t("pricing.free.cta")}</HTwo>
                  <StyledParagraph></StyledParagraph>
                </div>
              </div>
              <ul>
                <li>
                  <Strong>{t("pricing.free.0.header")}</Strong>
                  <StyledParagraph>{t("pricing.free.0.text")}</StyledParagraph>
                </li>
                <li>
                  <Strong>{t("pricing.free.1.header")}</Strong>
                  <StyledParagraph>{t("pricing.free.1.text")}</StyledParagraph>
                </li>
                <li>
                  <Strong>{t("pricing.free.2.header")}</Strong>
                  <StyledParagraph>{t("pricing.free.2.text")}</StyledParagraph>
                </li>
              </ul>
              <Button 
                type="primary" 
                size="large"
                href={formLinks[i18n.language]?.free}
                target="_blank"
              >{t("pricing.free.buttonText")}</Button>
            </Panel>
          </Col>
          <Col sm={24} md={12}>
            <LightPanel>
              <div>
                <div>
                  <Strong>{t("pricing.premium.header")}</Strong>
                  <StyledParagraph>{t("pricing.premium.subHeader")}</StyledParagraph>
                </div>
                <div>
                  <HTwo>{t("pricing.premium.cta")}</HTwo>
                  <StyledParagraph>{t("pricing.premium.ctaSub")}</StyledParagraph>
                </div>
              </div>
              <ul>
                <li>
                  <Strong>{t("pricing.premium.0.header")}</Strong>
                  <StyledParagraph>{t("pricing.premium.0.text")}</StyledParagraph>
                </li>
                <li>
                  <Strong>{t("pricing.premium.1.header")}</Strong>
                  <StyledParagraph>{t("pricing.premium.1.text")}</StyledParagraph>
                </li>
                <li>
                  <Strong>{t("pricing.premium.2.header")}</Strong>
                  <StyledParagraph>{t("pricing.premium.2.text")}</StyledParagraph>
                </li>
              </ul>
              <Button 
                type="primary" 
                size="large"
                href={formLinks[i18n.language]?.premium}
                target="_blank"
              >{t("pricing.premium.buttonText")}</Button>
            </LightPanel>
          </Col>  
        </StyledRow>
      </SlimContentContainer>
    </StyledSectionContainer>
  )
}
const StyledParagraph = styled(Paragraph)`
  margin: 0;
  min-height: 27px;
  white-space: pre-wrap;
`;
const Strong = styled(StyledParagraph)`
  font-weight: 500;
  color: rgba(251, 251, 251, 1);
  font-size: 16px;
  display: block;
  margin: 0;
`;
const StyledRow = styled(Row)`
  margin-top: 80px;
  position: relative;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    left: -33%; top: -33%; width: 167%; height: 167%;
    background: radial-gradient(circle at 50% 50%, var(--faded-gradient-color), transparent 70%);
    z-index: 1;
  }
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0;
  background: var(--primary-color-dark);
  @media (max-width: 576px) {
    text-align: center;
    padding: 0;
  }
`;
const Panel = styled.div`
  border: 1px solid rgba(253, 253, 253, 0.1);
  margin-bottom: 20px;
  border-radius: 6px;
  text-align: left;
  background: var(--primary-color-dark);
  z-index: 2;
  position: relative; 
  @media(min-width: 768px) {
    min-height: 760px;
  }
  display: flex;
  flex-direction: column;
  & > ul {
    flex-grow: 1;
    margin: 20px 10px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  & > ${Button} {
    margin: 10px 20px;
    margin-bottom: 30px;
    width: calc(100% - 40px);
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  & > div:first-child {
    @media(min-width: 768px) {
      min-height: 280px;
    }
    border-bottom: 1px solid rgba(253, 253, 253, 0.1);
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & ${HTwo} {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
  & li {
    margin-top: 20px;
    position: relative;
    min-height: 120px;
    @media(min-width: 768px) {
      & ${StyledParagraph}:not(${Strong}) {
        min-height: 125px;
      }
    }
    @media(max-width: 768px) {
      min-height: unset;
    }
    &:before {
      content: "";
      background: url(${checkLight});
      background-size: contain;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      position: absolute;
      left: -26px;
      top: 8px;
    }
  }
  `;

const LightPanel = styled(Panel)`
  background: white;
  & ${HTwo}, ${StyledParagraph} {
    color: var(--primary-color-dark);
  }
  &&& ${Button}, &&& ${Button}:hover {
    background: var(--primary-color-dark);
    color: white;
  }
  & > div:first-child {
    border-bottom: 1px solid rgba(16, 24, 32, 0.3);
  }
  & li {
    &:before {
      background: url(${checkDark});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`;
export default Simple;