import styled from "styled-components"; 
import { Row, Col } from 'antd';
import { useTranslation, Trans } from "react-i18next";
import { SectionContainer, HTwo, Paragraph } from "components";
import useMediaQuery from "hooks/useMediaQuery";
import aboutOurAppBd from "assets/images/about-our-app-bd.png";
import aboutOurAppBg from "assets/images/about-our-app-bg.png";
import aboutOurAppFg from "assets/images/about-our-app-fg.png";
import about from "assets/images/about.png";

const AboutOurApp = () => {

  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width: 768px)')

  if (isMobile) {
    return (
      <StyledSectionContainer>
        <StyledImg src={aboutOurAppBg} alt="Document" />
        <StyledRow>
          <Col md={12} sm={0}>
          </Col>
          <Col md={12} sm={24}>
            <HTwo><Trans i18nKey="aboutOurApp.header" components={[<span />]} /></HTwo>
            <Paragraph>{t("aboutOurApp.description")}</Paragraph>
            <ol>
              <li>
                <Paragraph>
                  <Trans 
                    i18nKey="aboutOurApp.text.0"
                    components={[<strong />]}
                  />
                </Paragraph>
              </li>
              <li>
                <Paragraph>{t("aboutOurApp.text.1")}</Paragraph>
              </li>
            </ol>
          </Col>
        </StyledRow>
        <CenterImg src={aboutOurAppFg} alt="Document" />
      </StyledSectionContainer>
    )
  } else {
    return (
      <StyledSectionContainer>
        <DesktopImg src={about} alt="Document" />
        <StyledRow>
          <Col md={12} sm={0}>
          </Col>
          <Col md={12} sm={24}>
            <HTwo><Trans i18nKey="aboutOurApp.header" components={[<span />]} /></HTwo>
            <Paragraph>{t("aboutOurApp.description")}</Paragraph>
            <ol>
              <li>
                <Paragraph>
                  <Trans 
                    i18nKey="aboutOurApp.text.0"
                    components={[<strong />]}
                  />
                </Paragraph>
              </li>
              <li>
                <Paragraph>{t("aboutOurApp.text.1")}</Paragraph>
              </li>
            </ol>
          </Col>
        </StyledRow>
      </StyledSectionContainer>
    )
  }
}

const DesktopImg = styled.img`
  height: min(90%, 40vw);
  position: absolute;
  left: 0;
  bottom: 0;
`;
const StyledRow = styled(Row)`
  width: 90%;
  padding-bottom: 150px;
  @media (max-width: 1200px) {
    padding: 60px 0;
  }
  @media (max-width: 768px) {
    padding: 40px 0;
  }
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 00;
  
  @media (max-width: 768px) {
    padding: 20px;
  }
  background-image: url(${aboutOurAppBd});
  background-size: cover;
  text-align: left;
  flex-direction: column;
  ol {
    list-style: none;
    counter-reset: li;
    li {
      counter-increment: li;
      position: relative;
      &:before {
        content: counter(li); 
        color: white;
        font-weight: 600;
        text-shadow: 0px 0px 5px var(--primary-color-dark);
        background: var(--primary-green);
        position: absolute;
        top: 1px;
        left: -40px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
      }
    }
  }
  position: relative;
`;
const StyledImg = styled.img`
  position: absolute;
  left: 0;
  height: 90%;
  /* top: 40px; */
  @media (max-width: 1300px) {
    transform: translate(-10%, 0);
  }
  @media (max-width: 1200px) {
    transform: translate(-25%, 0);
  }
  @media (max-width: 1000px) {
    transform: translate(-40%, 0);
  }
  @media (max-width: 850px) {
    /* transform: translate(-80%, 0); */
    top: unset;
    width: unset;
    bottom: 0px;
    height: 33vh;
  }
  margin: auto;
  align-self: center;
`;
const CenterImg = styled.img`
  margin: auto;
  height: 50vh;
  position: relative;
  margin-top: 0px;
  margin-bottom: -40px;
  
  height: 33vh;
  left: 25%;
`;
export default AboutOurApp;
