import styled from "styled-components"; 
import { useTranslation, Trans } from "react-i18next";
import { Row, Col } from 'antd';
import { SlimContentContainer, ContentContainer, SectionContainer, HTwo, Phone, Paragraph } from "components";
import qr1 from "assets/images/qr1.png";
import qr2 from "assets/images/qr2.png";
import qr3 from "assets/images/qr3.png";

const colors = [
  "rgba(135, 141, 149, 1)",
  "rgba(123, 199, 138, 1)",
  "rgba(205, 80, 80, 1)"
]

const qrs = [qr1, qr2, qr3]

const TestThemYourself = () => {

  const { t } = useTranslation()

  return (
    <StyledSectionContainer>
      <ContentContainer>
        <SlimContentContainer style={{ padding: 0 }}>
          <HTwo><Trans i18nKey="testThemYourself.header" components={[<span />]} /></HTwo>
          <Paragraph>{t("testThemYourself.subHeader")}</Paragraph>
        </SlimContentContainer>
        <StyledRow>
          {colors.map((color, i) => (
            <StyledCol xs={24} sm={24} md={8} $align={i % 2 === 0 && "left"} key={i}>
              <QRImage src={qrs[i]} alt="QR Code" />
              <StyledPhone color={color} />
            </StyledCol>
          ))}
        </StyledRow>
      </ContentContainer>
    </StyledSectionContainer>
  )
}
const StyledPhone = styled(Phone)`
  width: 100%;
  @media (max-width: 576px) {
    width: 80%;
  }
`;
const StyledCol = styled(Col)`
  position: relative;
  text-align: center;
  @media(max-width: 768px) {
    & svg {
      position: absolute;
      left: ${props => props.$align === "left" && '-40vw'};
      right: ${props => props.$align !== "left" && '-40vw'};
      top: -10px;
      transform: ${props => props.$align === "left" ? 'rotate(90deg)' : 'rotate(-90deg)'};
      height: 175px;
    }
  }
`;
const QRImage = styled.img`
  margin: 20px auto;
  @media(max-width: 768px) {
    height: 50%
  }
  @media(min-width: 768px) {
    width: 60%;
  }
  @media(min-width: 992px) {
    width: 40%;
  }
`;
const StyledRow = styled(Row)`
  margin-top: 80px;
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0;
  padding-bottom: 0px;
  & ${HTwo} {
    box-sizing: border-box;
  }
  background: var(--primary-color-dark);
  background-image: radial-gradient(circle at 50% 150%, var(--faded-gradient-color), transparent 70%);
  @media (max-width: 576px) {
    padding: 30px;
    text-align: left;
  }
`;
export default TestThemYourself;
