import styled from "styled-components";
import Vimeo from "@vimeo/player";
import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Button, ContentContainer, SectionContainer, HOne, HTwo, Paragraph } from "components";
import formLinks from "utils/formLinks";


const enVideoId = '911163326'
const nlVideoId = '911163116'

let player;
export default () => {

  const [isPlaying, setIsPlaying] = useState(false)
  const [videoId, setVideoId] = useState(enVideoId)

  const { i18n, t } = useTranslation()

  useEffect(() => {
    const iframe = document.querySelector('iframe');
    player = new Vimeo(iframe);
  }, [])

  useEffect(() => {
    if (i18n.language == "nl") {
      setVideoId(nlVideoId)
    } else {
      setVideoId(enVideoId)
    }
    player.setCurrentTime(0)
    setIsPlaying(false)
  }, [i18n.language])

  const togglePlaying = () => {
    isPlaying ? player.pause() : player.play()
    setIsPlaying(prev => !prev)
  }

  return (
    <StyledSectionContainer id="aboutUs">
      <StyledContentContainer>
        <HTwo><Trans i18nKey="video.header" components={[<span />]} /></HTwo>
        <VideoContainer onClick={() => togglePlaying()}>
          <StyledIFrame 
            src={`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&sidedock=0&controls=false`}
            width="100%" 
            height="100%" 
            frameBorder="0" 
            scrolling="no" 
            allow="autoplay; encrypted-media" 
            allowFullScreen=""
          ></StyledIFrame>
        </VideoContainer>
        <ButtonContainer>
          <Button
            size="large"
            target="_blank"
            href={formLinks[i18n.language]?.free}
            >{t("splash.signupButton")}</Button>
          <Button
            type="primary"
            size="large"
            target="_blank"
            href={formLinks[i18n.language]?.premium}
          >{t("pricing.premium.buttonText")}</Button>
          <Button
            size="large"
            target="_blank"
            href={formLinks[i18n.language]?.contactUs}
          >{t("globals.contactUs")}</Button>
        </ButtonContainer>
      </StyledContentContainer>
    </StyledSectionContainer>
  )
}

const VideoContainer = styled.div`
  min-height: 500px;
  width: 100%;
  position: relative;
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000000;
    top: 0; left: 0; 
    content: "";
    cursor: pointer;
  }
  @media(max-width: 768px) {
    min-height: 300px;
  }
`;

const StyledIFrame = styled.iframe`
  min-height: 500px;
  @media(max-width: 768px) {
    min-height: 300px;
  }
  margin: auto;
`;
const StyledContentContainer = styled(ContentContainer)`
  width: 100%;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-top: 40px;
  @media(max-width: 768px) {
    flex-direction: column;
    & > ${Button} {
      width: 100%;
    }
    margin-top: 0;
  }
  & > ${Button} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
`
const StyledSectionContainer = styled(SectionContainer)`
  background: var(--primary-color-dark);
  padding: 80px 0;
  padding-bottom: 0;
  text-align: center;
  & ${HOne} {
    padding: 0 40px;
  }
  & button {
    margin: 10px;
  }
  & ${HOne}, ${HTwo} {
    margin-top: 90px;
  }
  & ${Paragraph} {
    margin-bottom: 40px;
  }
  @media (max-width: 576px) {
    padding: 0;
  }
`;