import {
  Splash,
  Integrations,
  OurSolution,
  AboutOurApp,
  TestThemYourself,
  WhyChooseF5,
  Dashboard,
  VisualizeSuccess,
  Simple,
  Customize,
  Whitepaper,
  RealInsights,
  Testimonials,
  FAQ,
  Video
} from "features";
import useGDPRCompliancePopup from "hooks/useGDPRCompliancePopup";

function Root() {

  useGDPRCompliancePopup()

  return (
    <>
      <Splash />
      <Video />
      <Integrations />
      <OurSolution />
      <AboutOurApp />
      <TestThemYourself />
      <WhyChooseF5 />
      <Dashboard />
      <VisualizeSuccess />
      <Simple />
      <Customize />
      <Whitepaper />
      <RealInsights />
      <Testimonials />
      <FAQ />
    </>
  );
}

export default Root;
