import styled from "styled-components";
import { useState } from "react";
import { useSwipeable } from "react-swipeable";

const Carousel = ({ children }) => {
  
  const [active, setActive] = useState(0);

  const next = () => (active + 1) % children.length
  const prev = () => active === 0 ? children.length - 1 : active - 1

  const handlers = 
  useSwipeable({
    onSwipedLeft: () => setActive(next()),
    onSwipedRight: () => setActive(prev())
  });

  return (
    <OuterContainer>
      <Container {...handlers}>
        {children.map((child, i) => (
          <Slide 
            key={i}
            $prev={i === prev()} 
            $next={i === next()} 
            $active={i === active}
            onClick={() => {
              if (i === prev()) {
                setActive(prev())
              } else if (i === next()) {
                setActive(next())
              }
            }}
          >
            { child }
          </Slide>
        ))}
      </Container>
      <DotsContainer>
        {children.map((_, i) => (
          <Dot 
            key={i} 
            $active={i === active} 
            onClick={() => setActive(i)}
          />
        ))}
      </DotsContainer>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;
const Container = styled.div`
  display: flex;
  position: relative;
  height: 67%;
  width: 100%;
`;
const DotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  @media(max-width: 1200px) {
    margin-top: 60px;
  }
`;
const Slide = styled.div`
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  position: absolute;
  left: 50%;  
  top: 50%;
  width: 75%;
  transform: ${props => {
    if (props.$active)  {
      return 'scale(1) translate(-50%, -50%)';
    } else if (props.$next) {
      return 'translate(-20%, -50%) scale(0.75)';
    } else if (props.$prev) {
      return 'translate(-80%, -50%) scale(0.75)';
    } else {
      return 'scale(0)';
    }
  }};
  opacity: ${props => {
   if (props.$active)  {
      return 1;
    } else if (props.$next || props.$prev) {
      return 0.3;
    } else {
      return 0;
    }
  }};
  z-index: ${props => props.$active ? 2 : 1};
  transition: all 500ms;
`;
const dotSize = 12
const Dot = styled.div`
  height: ${dotSize}px; 
  width: ${dotSize}px;
  margin-right: ${dotSize * 2}px;
  border-radius: ${dotSize}px;
  background-color: ${props => props.$active ? 'var(--primary-green)' : 'rgba(44, 66, 85, 1)' };
  transition: background-color 100ms;
  cursor: pointer;
`;

export default Carousel;