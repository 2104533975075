import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link as UnstyledLink } from 'react-router-dom';
import { MailOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import _ from "lodash";
import { Paragraph, Link } from "components";
import twitter from "assets/images/twitter.png"
import discord from "assets/images/discord.png"
import linkedin from "assets/images/linkedin.svg"
import contact from "assets/images/mail.svg"
import logo from "assets/images/logo-full.png"
import { trackClick } from "utils/gtag";
import formLinks from "utils/formLinks";

const { Footer } = Layout;

const sections = [
  "home",
  "aboutUs",
  "features",
  "pricing",
  "demo",
  "faq"
]
const AppFooter = () => {

  const { t, i18n } = useTranslation()

  return (
    <StyledFooter>
      <LogoImg src={logo} alt="F5" />
      <Links> 
        {sections.map((section) => <StyledLink key={section} href={`#${section}`}>{t(`globals.${section}`)}</StyledLink>)}
      </Links>
      <StyledParagraph>
        {t("footer.text")}
      </StyledParagraph>
      <div>
        <a target="_blank" href="https://www.linkedin.com/company/f5-scan/?viewAsMember=true" >
          <SocialImg src={linkedin} alt="Linkedin icon" style={{ height: 25, padding: 3 }} />
        </a>
        <a href="#" onClick={() => trackClick("X")} >
          <SocialImg src={twitter} alt="Twitter icon" />
        </a>
        <a href="#" onClick={() => trackClick("Discord")}>
          <SocialImg src={discord} alt="Discord icon" />
        </a>
        <a target="_blank" href={formLinks[i18n.language]?.contactUs}>
          <SocialImg src={contact} alt="Mail icon" />
        </a>
      </div>
      <Paragraph>2024 F5 Scan - {t("globals.rightsReserved")}</Paragraph>
      <ToSBox>
        <UnstyledLink to="/privacy" onClick={() => trackClick("Privacy Policy")}>{t("globals.privacyPolicy")}</UnstyledLink>
        <UnstyledLink to="/terms">{t("globals.termsAndConditions")}</UnstyledLink>
        <UnstyledLink to="" onClick={() => window.FreshworksWidget('open')}>{t("globals.support")}</UnstyledLink>
        <UnstyledLink target="_blank" to={formLinks[i18n.language]?.contactUs}>{t("globals.contactUs")}</UnstyledLink>
      </ToSBox>
    </StyledFooter>
  )
}

const StyledParagraph = styled(Paragraph)`
  padding: 0 20px;
  max-width: 820px;
`;
const Links = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media(max-width: 576px) {
    flex-direction: column;
    margin-top: 20px;
  }
  `;
const StyledLink = styled(Link)`
  padding: 30px 20px;
  @media(max-width: 576px) {
    padding: 16px 0;
  }
  color: white;
  font-weight: 500;
  font-size: 16px;
`;
const LogoImg = styled.img`
  max-width: 140px;
`;
const SocialImg = styled.img`
  margin: 10px 10px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;
const StyledFooter = styled(Footer)`
  display: flex;
  flex-direction: column;
  background: rgba(14, 21, 29, 1);
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  @media(max-width: 576px) {
    padding: 60px 30px;
    & ${StyledParagraph} {
      display: none;
    }
  }
`;
const ToSBox = styled.div`
  & a {
    text-decoration: underline;
    color: var(--text-color);
    margin: 10px;
    position: relative;
    top: 10px;
  }
`;

export default AppFooter;