
import styled from 'styled-components';
import { useState } from 'react';
import { Collapse } from 'antd';
import { useTranslation, Trans } from "react-i18next";
import { SectionContainer, ContentContainer, HTwo } from "components";
import plusIcon from "assets/images/plus-icon.png";
const { Panel } = Collapse;


const FAQ = () => {

  const { t } = useTranslation()
  const [active, setActive] = useState([])

  return (
    <StyledSectionContainer id="faq">
      <ContentContainer>
        <HTwo><Trans i18nKey="faq.header" components={[<span />]} /></HTwo>
        <StyledCollapse defaultActiveKey={[]} activeKey={active}>
          {new Array(3).fill(true).map((item, i) => (
            <StyledPanel 
              header={t(`faq.${i}.header`)} 
              key={`panel-${i}`}
              forceRender={true}
              showArrow={false}
              onClick={()=> {
                if (active.indexOf(`panel-${i}`) === -1) {
                  setActive([...active, `panel-${i}`])
                } else {
                  setActive((prev) => prev.filter((key) => key !== `panel-${i}`))
                }
              }}
            >
              {t(`faq.${i}.text`)}
            </StyledPanel>
          ))}
        </StyledCollapse>
      </ContentContainer>
    </StyledSectionContainer>
  );
}

const StyledPanel = styled(Panel)`
  position: relative;
  cursor: pointer;
  &:after {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    top: calc(50% - 12px); right: 25px;
    @media(max-width: 576px) {
      right: 10px;
    }
    background-image: url(${plusIcon});
    pointer-events: none;
    background-size: contain;
    transform: rotate(0deg);
    transition: transform 100ms;
  }
  &.ant-collapse-item-active {
    &:after {
      transform: rotate(45deg);
      transition: transform 100ms;
    }
  }
`;
const StyledCollapse = styled(Collapse)`
  width: 67vw;
  @media(max-width: 576px) {
    width: 100%;
  }
  text-align: left;
  margin-top: 60px;
  color: var(--text-color);
  border-radius: 6px;
  border: none;
  & .ant-collapse-content>.ant-collapse-content-box {
    padding-top: 0;
    width: 90%;
  }
  & .ant-collapse-content {
    color: rgba(248, 248, 248, 0.7);
    background: transparent;
    padding: 20px;
    padding-top: 0;
    border-radius: 6px;
    font-size: 16px;
    border-top: none;
  }
  & .ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
    color: var(--text-color);
    padding: 20px 30px;
    font-weight: 600;
    font-size: 18px;
    
  }
  &.ant-collapse>.ant-collapse-item {
    border: 1px solid rgba(248, 248, 248, 0.2);
    margin: 20px 0;
    border-radius: 4px;
    
    &.ant-collapse-item-active {
      border-color: rgba(248, 248, 248, 1);
    }

  }
  & .ant-collapse-item:last-child>.ant-collapse-content {
    border-radius: 6px;
  }
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0px;
  @media(max-width: 576px) {
    padding: 30px;
  }
`;

export default FAQ;