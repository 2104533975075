import styled from 'styled-components';

export default styled.div`
  background: none;
  padding: 20px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 576px) {
    padding: 30px;
    text-align: left;
  }
  
`;