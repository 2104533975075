import Layout from "layout/Layout";
import Header from "layout/Header";
import Content from "layout/Content";
import Footer from "layout/Footer";

export default function useWithLayout(children) {
  return (
    <Layout>
      <Header />
      <Content>
        {children}
      </Content>
      <Footer />
    </Layout>
  );
}