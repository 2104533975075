import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Row, Col } from 'antd';
import { ContentContainer, SectionContainer, SlimContentContainer, HTwo, HThree, Paragraph } from "components";
import insights from "assets/images/insights.png";
import insightsIcon from "assets/images/insights-icon.png";

const items = [
  { 
    header: "20% of work time",
    text: "is spent searching for right documents and information"
  },
  { 
    header: "20% of work time",
    text: "is spent searching for right documents and information"
  },
  { 
    header: "20% of work time",
    text: "is spent searching for right documents and information"
  },
]

const RealInsights = () => {

  const { t } = useTranslation()

  return (
    <StyledSectionContainer>
      <StyledContentContainer>
        <SlimContentContainer>
          <HTwo><Trans i18nKey="realInsights.header" components={[<span />]} /></HTwo>
          <Paragraph>{t("realInsights.description")}</Paragraph>
        </SlimContentContainer>
        <ItemContainer gutter={60}>
          {items.map((item, i) => (
            <Item md={24} lg={8} key={i}>
              <div>
                <div>
                  <HThree>
                    {t(`realInsights.${i}.header`)}
                    {/* <StyledImg src={insightsIcon} alt="Upwards trend" /> */}
                  </HThree>
                </div>
                <Paragraph>{t(`realInsights.${i}.text`)}</Paragraph>
              </div>
            </Item>
          ))}
        </ItemContainer>
      </StyledContentContainer>
    </StyledSectionContainer>
  )
}

const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0;
  @media(max-width: 576px) {
    padding: 20px 0px;
  }
  & ${HTwo} {
    padding-bottom: 20px;
  }
  background-image: url(${insights});
  background-size: cover;
  @media(max-width: 576px) {
    background-position: top;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0; left: 0; width: 100%; height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }
`;
const StyledContentContainer = styled(ContentContainer)`
  position: relative;
  z-index: 2;
`;
const ItemContainer = styled(Row)`
  margin-top: 60px;
  padding: 0 30px;
  justify-content: center;
`;
const StyledImg = styled.img`
  height: 1rem;
  width: auto;
  margin-left: 10px;
  object-fit: contain;  
`;
const Item = styled(Col)`
  flex-grow: 1;
  margin-bottom: 10px;
  & > div {
    height: 100%;
    text-align: left;
    padding: 20px;
    padding-bottom: 10px;
    border: 1px solid var(--primary-green);
    background: var(--primary-color-dark);
    border-radius: 6px;
    & > div {
      margin-bottom: 10px;
      & ${StyledImg} { }
      & ${HThree} {
        color: var(--primary-green);
        margin: 0;
        display: inline;
      }
    }
    & ${Paragraph} {
      color: white;
    }
  }
`;
export default RealInsights;
