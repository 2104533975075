import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Row, Col } from 'antd';
import { Carousel, SectionContainer, ContentContainer, HTwo, Paragraph } from "components";
import sarah from "assets/images/sarah.png";
import david from "assets/images/david.png";
import lucas from "assets/images/lucas.png";
import pieter from "assets/images/pieter.jpeg";

const imgs = [sarah, david, lucas, pieter]

const Testimonials = () => {

  const { t } = useTranslation()

  return (
    <StyledSectionContainer>
      <ContentContainer>
        <StyledRow>
          <StyledCol lg={24} xl={12}>
            <HTwo><Trans i18nKey="testimonials.header" components={[<span />]} /></HTwo>
            <Paragraph>{t("testimonials.subHeader")}</Paragraph>
          </StyledCol>
          <StyledCol xs={24}lg={24} xl={12}>
            <Carousel>
              {new Array(4).fill(true).map((_, i) => (
                <Slide key={i}>
                  <Paragraph>{t(`testimonials.${i}.text`)}</Paragraph>
                  <div>
                    <StyledImg src={imgs[i]} alt="Sarah Jansen" />
                    <div>
                      <Strong>{t(`testimonials.${i}.name`)}</Strong>
                      <Paragraph>{t(`testimonials.${i}.position`)}</Paragraph>
                    </div>
                  </div>
                </Slide>
              ))}
            </Carousel>
          </StyledCol>
        </StyledRow>
      </ContentContainer>
    </StyledSectionContainer>
  )
}
const StyledImg = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
`;
const Strong = styled(Paragraph)`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: white;
  line-height: 1;
`;
const StyledCol = styled(Col)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  min-height: 300px;
  @media(max-width: 1200px) {
    margin: 0;
  }
  @media(max-width: 576px) {
    justify-content: start;
  }
`;
const StyledRow = styled(Row)`
  width: 100%;
  justify-content: center;
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0;
  @media(max-width: 1200px) {
    padding: 30px;
  }
  text-align: left;
  flex-direction: column;
  background: var(--primary-color-dark);
  background-image: radial-gradient(circle at 50% 150%, var(--faded-gradient-color), transparent 70%);
  `;
const Slide = styled.div`
  padding: 20px;
  /* padding-bottom: 5px; */
  border-radius: 6px;
  background: rgba(11, 18, 24, 1);
  border: 1px solid rgba(54, 84, 115, 0.5);
  /* min-width: 400px;
  max-width: 500px; */
  width: 100%;
  @media(max-width: 576px) {
    min-width: 75vw;
  }
  & > div {
    display: flex;
    flex-direction: row;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 50px;
      & ${Paragraph} {
        margin-bottom: 0;
      }
    }
  }
`;
export default Testimonials;