import styled from "styled-components";

export default styled.div`
  margin: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
  @media (min-width: 1400px) {
    max-width: 1200px;
  }
  @media (min-width: 1600px) {
    max-width: 1300px;
  }
  @media (min-width: 2000px) {
    max-width: 1400px;
  }
`;
