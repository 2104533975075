import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Row } from 'antd';
import { SlimContentContainer, ContentContainer, SectionContainer, HTwo, Paragraph } from "components";
import splashBg from "assets/images/splash-bg.png";


const WhyChooseF5 = () => {

  const { t } = useTranslation()

  return (
    <StyledSectionContainer>
      <ContentContainer>
        <SlimContentContainer>
          <HTwo><Trans i18nKey="why.header" components={[<span />]} /></HTwo>
        </SlimContentContainer>
        <ListContainer>
          <StyledRow>
            <StyledParagraph>{t("why.0.text")}</StyledParagraph>
          </StyledRow>
          <StyledRow $justify="end">
            <StyledParagraph $align="left">{t("why.1.text")}</StyledParagraph>
          </StyledRow>
          <StyledRow>
            <StyledParagraph>{t("why.2.text")}</StyledParagraph>
          </StyledRow>
        </ListContainer>
      </ContentContainer>
    </StyledSectionContainer>
  )
}

const ListContainer = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: calc(50% - 1px);
    top: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(to bottom, transparent, var(--primary-green) 20px, var(--primary-green) calc(100% - 20px), transparent 100%);
    @media(max-width: 576px) {
      left: 20px;
    }
  }
  `;
const StyledParagraph = styled(Paragraph)`
  text-align: ${props => props.$align || "right"};
  width: 50%;
  padding: 0 30px;
  @media(max-width: 576px) {
    width: 100%;
    text-align: left;
    margin-left: 20px;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: var(--primary-green);
    box-shadow: 0px 0px 5px var(--primary-green);
    @media(max-width: 576px) {
      left: 17px;
      top: 20px;
    }
  }
  `;
const StyledRow = styled(Row)`
  justify-content: ${props => props.$justify || 'start'};
  position: relative;
  `;
const StyledSectionContainer = styled(SectionContainer)`
  background: var(--primary-color-darker);
  @media(max-width: 576px) {
    padding: 20px 0;
  }
  padding: 80px 0;
  &:after {
    content: "";
    position: absolute;
    top: 0; left: 0; width: 100%; height: 100%;
    background-image: url(${splashBg});
    background-size: 26px 26px;
  }
`;
export default WhyChooseF5;
