import styled from "styled-components";
import { useEffect } from "react";
import { Modal } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Paragraph } from "components";

const gdprKey = 'hasApprovedGDPR-1.1'

export default () => {

  const { t } = useTranslation()

  const setupAnalytics = (hasApproved) => {
    if (!hasApproved) {
      window['ga-disable-G-5KH2Y6KDE4'] = true;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-5KH2Y6KDE4');
  }

  useEffect(() => {
    const hasApproved = localStorage.getItem(gdprKey)
    if (_.isNull(hasApproved)) {
      Modal.destroyAll()
      const modal = Modal.success({
        footer: () => (
          <ButtonContainer>
            <Button 
              onClick={() => {
                localStorage.setItem(gdprKey, false)
                setupAnalytics()
                modal.destroy()
              }}
            >Allow necessary cookies only</Button>
            <Button 
              type="primary" 
              onClick={() => {
                localStorage.setItem(gdprKey, true)
                setupAnalytics(false)
                modal.destroy()
              }}
            >Allow all cookies</Button>
          </ButtonContainer>
        ), 
        content: (<StyledParagraph>{t("globals.gdprPolicy")}</StyledParagraph>),
      })
    } else {
      setupAnalytics(hasApproved)
    }
  }, [])
}

const StyledParagraph = styled(Paragraph)`
  white-space: pre-line;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  & ${Button}${Button}${Button} {
    margin: 5px 0;
    width: 100%;
    margin-inline-start: 0;
  }
`;