import styled from 'styled-components';
import { Button } from 'antd';

export default styled(Button)`
  &&& {
    font-weight: 500;
    border-radius: 2px;
    &.ant-btn-lg {
      padding: 0px 20px;
      height: 48px;
      min-width: 148px;
    }
    &.ant-btn-default {
      background: var(--primary-color-dark);
      color: var(--off-white);
      &:hover {
        color: white;
        border-color: white;
      }
    }
    &.ant-btn-primary {
      background: var(--off-white);
      color: var(--primary-color-dark);
      &:hover {
        background: white;
      }
    }
    &:hover {
      box-shadow: 0px 0px 10px var(--faded-gradient-color);
    }
  }
`;