import styled from "styled-components"; 
import _ from "lodash";
import { Row } from 'antd';
import { useTranslation, Trans } from "react-i18next";
import { SlimContentContainer, ContentContainer, SectionContainer, HTwo, Paragraph } from "components";
import * as partners from "assets/images/partners";

const partnerUrls = {
  aconex: "https://www.oracle.com/nl/construction-engineering/aconex/",
  bim360: "https://www.autodesk.com/bim-360/",
  bricsys: "https://www.bricsys.com/nl-nl",
  catenda: "https://catenda.com/",
  procore: "https://www.procore.com/en-gb",
  relatics: "https://construction.autodesk.com/?utm_medium=organic&utm_source=bim-360-website&utm_campaign=build-demo&utm_audience=retargeting&utm_content=text&utm_region=amer&_ga=2.212856540.914496400.1702462562-889765754.1701084963",
  sharepoint: "https://www.microsoft.com/nl-nl/microsoft-365/sharepoint/collaboration",
  thinkproject: "https://thinkproject.com/",
  trimbleconnect: "https://connect.trimble.com/",
}

const Integrations = () => {

  const { t } = useTranslation()

  return (
    <StyledSectionContainer>
      <ContentContainer>
        <StyledSlimContentContainer>
          <HTwo><Trans i18nKey="integrations.header" components={[<span />]} /></HTwo>
          <Paragraph>{t("integrations.description")}</Paragraph>
        </StyledSlimContentContainer>
        <StyledRow>
          <Partners>
            {_.map(partners, (imgUrl, key) => (
              <a href={partnerUrls[key]} target="_blank" key={imgUrl}>
                <PartnerLogo src={imgUrl} alt={_.startCase(imgUrl)} />
              </a>
            ))}
          </Partners>
        </StyledRow>
      </ContentContainer>
    </StyledSectionContainer>
  )
}

const StyledSlimContentContainer = styled(SlimContentContainer)``;
const Partners = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-image: radial-gradient(circle at 50% 150%, var(--faded-gradient-color), transparent 50%);
  padding-bottom: 60px;
  @media (max-width: 576px) {
    flex-wrap: nowrap;
    width: 200px;
  }
  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const PartnerLogo = styled.img`
  width: 200px;
  margin: 40px 70px;
  object-fit: contain;
  @media (max-width: 576px) {
    margin: 0 20px;
    width: 160px;
  }
  `;
const StyledRow = styled(Row)`
  margin-top: 80px;
  overflow: auto;
  max-width: 100vw;
  `;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0;
  padding-bottom: 0px;
  background: var(--primary-color-dark);
  background-image: radial-gradient(circle at 50% 150%, var(--faded-gradient-color), transparent 70%);
  @media (max-width: 576px) {
    padding: 0px;
  }
`;
export default Integrations;