import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Button, SlimContentContainer, MedWidthContainer, ContentContainer, SectionContainer, HOne, HTwo, Paragraph } from "components";
import splashBg from "assets/images/splash-bg-img.png";
import splash from "assets/images/splash-image.png";
import formLinks from "utils/formLinks";

const Splash = () => {

  const { t, i18n } = useTranslation()

  return (
    <StyledSectionContainer id="home">
      <ContentContainer>
        <MedWidthContainer>
          <HOne><Trans i18nKey="splash.header" components={[<span />]} /></HOne>
          <Paragraph>
            {t("splash.subHeader")}
          </Paragraph>
          <ButtonContainer>
            <Button
              type="primary"
              size="large"
              target="_blank"
              href={formLinks[i18n.language]?.premium}
            >{t("pricing.premium.buttonText")}</Button>
          </ButtonContainer>
        </MedWidthContainer>
        <StyledSlimContentContainer>
          <StyledImg src={splash} alt="Phones with statuses" />
        </StyledSlimContentContainer>
      </ContentContainer>
    </StyledSectionContainer>
  )
}
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-top: 40px;
  @media(max-width: 768px) {
    flex-direction: column;
    & > ${Button} {
      width: 100%;
    }
    margin-top: 0;
  }
  & > ${Button} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 67%;
    @media(max-width: 768px) {
      width: 100%;
    }
  }
`
const StyledSlimContentContainer = styled(SlimContentContainer)`
  padding-bottom: 0;
`;
const StyledSectionContainer = styled(SectionContainer)`
  background: var(--primary-color-dark);
  background-image: url(${splashBg});
  background-size: cover;
  /* min-height: 100vh; */
  padding-bottom: 0;
  text-align: center;
  & ${HOne} {
    padding: 0 40px;
  }
  & button {
    margin: 10px;
  }
  & ${HOne}, ${HTwo} {
    margin-top: 90px;
  }
  & ${Paragraph} {
    margin-bottom: 40px;
  }
  @media (max-width: 576px) {
    padding: 0;
  }
`;
const StyledImg = styled.img`
  width: 100%;
  object-fit: contain;

  @media (max-width: 576px) {
    position: relative;
    width: 140%;
    left: -20%;
    margin-top: 30px;
    padding-bottom: 0;
  }
  @media (min-width: 576px) {
    padding: 80px;
    margin-top: 0px;
    padding-bottom: 0;
    margin-bottom: -10px;
  }
  
  background:
  radial-gradient(circle at 29% 29%, var(--faded-gradient-color), transparent 31%),
  radial-gradient(circle at 71% 63%, var(--faded-gradient-color), transparent 31%);
  
  margin-top: 0px;
  padding-bottom: 0;
`;

export default Splash;
