import styled from "styled-components";
import ContentContainer from "components/ContentContainer";

export default styled(ContentContainer)`
  max-width: 920px;
  margin: auto;
  @media (max-width: 1200px) {
    max-width: 720px;
  }
  @media (max-width: 576px) {
    padding: 0 30px;
  }
`;