import styled from "styled-components"; 
import { useTranslation, Trans } from "react-i18next";
import { Row, Col } from 'antd';
import useMediaQuery from "hooks/useMediaQuery";
import { Button, SectionContainer, ContentContainer, HTwo, Paragraph } from "components";
import whitepaper from "assets/images/whitepaper.png";
import whitepaperMob from "assets/images/whitepaper-mob.png";
import formLinks from "utils/formLinks";

const Whitepaper = () => {

  const isMobile = useMediaQuery('(max-width: 576px)')
  const { t, i18n } = useTranslation()

  return (
    <StyledSectionContainer>
      <ContentContainer>
        <StyledRow>
          <ImgCol xs={0} sm={12}>
          </ImgCol>
          <StyledCol xs={24} sm={12}>
            <HTwo><Trans i18nKey="whitepaper.header" components={[<span />]} /></HTwo>  
            <Paragraph>{t("whitepaper.text")}</Paragraph>
            <div>
              <Button 
                type="primary" 
                size="large"
                href={formLinks[i18n.language]?.whitepaper}
                target="_blank"
              >{t("whitepaper.buttonText")}</Button>
            </div>
          </StyledCol>
          <ImgCol xs={0} sm={12}>
          </ImgCol>
          <StyledImg src={isMobile ? whitepaperMob : whitepaper} alt="Whitepaper" />
        </StyledRow>
      </ContentContainer>
    </StyledSectionContainer>
  )
}

const StyledCol = styled(Col)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: static;
  padding: 30px;
  z-index: 1;
  align-items: end;
  & ${Button} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    margin-top: 20px;
  }
`;
const StyledRow = styled(Row)`
  width: 100%;
  min-height: 50vh;
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 0;
  position: relative;
  & ${HTwo} {
    box-sizing: border-box;
  }
  text-align: right;
  flex-direction: column;
  @media(max-width: 1200px) {
    padding: 0px;
    padding-bottom: 0;
    padding-top: 0px;
    &:before {
      content: "";
      position: absolute;
      top: -16%; left: -16%; height: 133%; width: 133%;
      background: radial-gradient(circle at 25% 50%, var(--faded-gradient-color), transparent 60%);
    }
  }
`;
const StyledImg = styled.img`
  position: absolute;
  left: 0;
  height: 100%;
  object-fit: contain;
  z-index: 0;
  @media(max-width: 992px) {
    /* display: none; */
  }
  @media(max-width: 576px) {
    position: relative;
    height: unset;
    width: calc(100% + 60px);
    max-height: 50vh;
    left: -30px;
    object-fit: cover;
    object-position: top;
    left: 0;
  }
  @media(max-width: 622px) {
    display: block;
  }
`;
const ImgCol = styled(Col)`
  display: flex;
  justify-content: end;
  position: static;
  &:before {
    content: "";
    position: absolute;
    top: 0%; left: 0%; height: 100%; width: 100%;
    background: radial-gradient(circle at 0% 50%, var(--faded-gradient-color), transparent 45%);
  }
`;
export default Whitepaper;