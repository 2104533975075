export default {
  en: {
    premium: "https://webforms.pipedrive.com/f/6WfFZNFOXG56pVY9Z1HIaMwsO9Z3iuRhDqkMhckqJKgx70M0mf3N19W8VV9xQMhuKv",
    free: "https://webforms.pipedrive.com/f/6xQtCUGCafX83CGv7jhEAaZWudIo8iSfeu5ybOHHySapYthWGOvyUxxA0MfbAZVGRJ",
    contactUs: "https://webforms.pipedrive.com/f/cs71qOeofHD3wU2oqDWI8W40o6VLXC3YXhZC6hPEzFWjFcyhiGMAGDLEUKfNK34X1V",
    whitepaper: "https://webforms.pipedrive.com/f/bXzByECY6oah0GjwhcAoyTwA0hLjf4pP90rEi12WMrhtdqhJMkup3hCXVB795v5Vnl ",
}, nl: {
    free: "https://webforms.pipedrive.com/f/6xQQYi9Xfj3nwTrQsCc1fDgfrnSsyHcC3Ryg1WcwYV0lF4c3P85M1Jn7NGYbcx8TPJ",
    premium: "https://webforms.pipedrive.com/f/6k75OdqYMEO6OUM7dcfZrU5KKbdpVPy3rygQPVYTHZxeSBoauaQZ2yeeVZPsZ4E7Hd",
    whitepaper: "https://webforms.pipedrive.com/f/clR0FkLjZK6wiFDHkuw8hIZs22JyHH8YN4b7iLM0aqJNaQ7iGnykkI8sdhvhNEmwDh",
    contactUs: "https://webforms.pipedrive.com/f/6rHUbGjBssWrstq2LvviHRPd6OhXOtdfi8ykDfV9fqUhxBCDZBVIMBqj1yADAMOlmr",
  }
}