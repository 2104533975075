import styled from "styled-components";

export const HOne = styled.h1`
  width: 100%;
  font-size: clamp(28px, 2.4vw, 40px);
  letter-spacing: 2px;
  color: var(--text-color);
  position: relative;
  font-weight: 600;
  & span {
    color: var(--primary-green);
  }
`;

export const HTwo = styled.h2`
  width: 100%;
  font-size: clamp(28px, 2vw, 38px);
  color: var(--text-color);
  & span {
    color: var(--primary-green);
  }
`;

export const HThree = styled.h3`
  width: 100%;
  font-size: clamp(18px, 1.5vw, 28px);
  color: var(--text-color);
  font-weight: 500;
`;

export const Paragraph = styled.p`
  color: var(--quiet-text-color);
  font-size: 16px;
  line-height: 1.9;
  margin-top: 0;
`;

export const Link =  styled.a`
  color: var(--text-color);
  text-decoration: ${props => props.$active ? "underline" : "none"};
  &:hover {
    color: var(--primary-green);
  }
`;

const Typography = { HOne, HTwo, HThree, Paragraph, Link };

export default Typography;
